
import EncuestaModule from "@/store/modules/EncuestaModule";
import { ElMessage } from "element-plus";
import moment from "moment";
import "moment/locale/es";
import { useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Respuesta {
  puntuacion: number;
  comentario?: string;
}

export default defineComponent({
  props: {
    servicioId: String,
    identificador: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const module = getModule(EncuestaModule, store);
    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    onBeforeMount(() => {
      if (!props.servicioId || !props.identificador) {
        return router.replace("/404");
      }

      module
        .fetchServiciosProgramado({
          servicioId: props.servicioId,
          identificador: props.identificador,
        })
        .catch((err) => {
          if (err?.error?.statusCode === 422) {
            return router.replace("/encuesta-contestada");
          }
          router.replace("/404");
        });
    });
    const servicioColectivo = computed(() => module.servicio);

    const { handleSubmit } = useForm<Respuesta>({
      validationSchema: Yup.object({
        puntuacion: Yup.number().required().label(i18n("FamiliaDNILabel")),
        comentario: Yup.string().label(i18n("FamiliaTelefonoLabel")),
      }),
    });
    const { value: puntuacion } = useField("puntuacion");
    const { value: comentario } = useField("comentario");
    const envioLoad = ref(false);

    return {
      servicioColectivo,
      inicioPrevistoFormateado: computed(() => {
        return moment(servicioColectivo.value?.inicioPrevisto).format("LL");
      }),
      finPrevistoFormateado: computed(() => {
        return moment(servicioColectivo.value?.finPrevisto).format("LL");
      }),
      puntuacion,
      comentario,
      envioLoad,
      submit: handleSubmit(async (values) => {
        if (!props.servicioId || !props.identificador) {
          return router.replace("/404");
        }

        envioLoad.value = true;

        try {
          await module.responderServiciosProgramado({
            servicioId: props.servicioId,
            identificador: props.identificador,
            puntuacion: values.puntuacion,
            comentario: values.comentario ?? "",
          });
          router.replace("/encuesta-contestada");
        } catch (err) {
          console.error(err);
          ElMessage.error(t("formularioEnviadoError"));
        } finally {
          envioLoad.value = false;
        }
      }),
    };
  },
});
